.card-background {
    background: rgb(219,220,223);
    background: linear-gradient(90deg, rgba(219,220,223,0.6477941518404237) 0%, rgba(212,212,214,0.37608546836703427) 35%, rgba(187,193,194,0.5189426112241772) 100%);
}

.login-title {
    color: #000000;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-bottom: 5%;
}

.return-button {
    padding-top: 10px;
    text-align: center;
}