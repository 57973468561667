.body {
    padding: 2%;
}

.header {
    text-align: center;
    padding-bottom: 1%;;
}

.centered {
    margin: 0 auto; 
    float: none;
}