.link {
    text-decoration: none;
}

.welcome-text {
    text-align: right;
    color: #DC3545;
    padding-right: 15%;
}

.heading {
    color: #777272;
}